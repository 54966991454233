import React from "react"
import { Link } from "theme-ui"

const EventCta = ({ registrationCta, registrationLink, primaryColor, variant }) => {
  if (!registrationCta || !registrationLink) return null

  return (
    <Link
      href={registrationLink}
      variant="buttons.button"
      target="_blank"
      sx={{
        display: "inline-block",
        pt: "19px",
        backgroundColor: variant === "light" ? "light" : primaryColor?.hex || "primary",
        color: "white !important",
        ...variant === "light" && {
          color: "primary",
        },
        ...variant === "hero" && {
          flex: ["1 1 100%", "0 0 auto"],
          textAlign: "center",
        },
        ...variant === "agenda" && {
          mt: [4, 5, 0, 0],
          ml: [3],
        }
      }}
    >
      {registrationCta}
    </Link>
  )
}

export default EventCta 